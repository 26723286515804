import cx from 'clsx';
import type { ReactNode } from 'react';
import { Stack, Type } from 'src/components/core';

export interface NavFooterLinkColumnProps {
  className?: string;
  children: ReactNode;
  title: string | null;
}

export function NavFooterLinkColumn({
  className,
  children,
  title,
}: NavFooterLinkColumnProps) {
  return (
    <div className={cx('text-center sm:text-left', className)}>
      {title && <Type component="h4">{title}</Type>}
      <Stack
        component="ul"
        direction="vertical"
        className="gap-2 text-sm font-medium"
      >
        {children}
      </Stack>
    </div>
  );
}
