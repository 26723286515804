import moment from 'moment';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';

export function SimpleFooter() {
  const { t } = useTranslation(['new-footer']);

  return (
    <footer className="fixed bottom-0 left-0 flex w-full justify-center bg-white py-4 text-xs text-gray-400">
      <div className="flex max-w-screen-2xl gap-4 px-8">
        <p className="mx-auto text-nowrap font-normal">
          <span>{moment().format('YYYY')}</span> The Population Project ©
        </p>

        <Link href="/methodology" className="font-normal hover:underline">
          {t('methodology')}
        </Link>

        <Link href="/privacy" className="font-normal hover:underline">
          {t('privacy-footer')}
        </Link>
      </div>
    </footer>
  );
}
