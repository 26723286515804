import cx from 'clsx';
import moment from 'moment';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { SocialIconList } from 'src/components/social/socialIconList.component';

import { NavFooterLinkColumn } from './navFooterLinkColumn.component';
import { SimpleFooter } from './simpleFooter.component';

export interface NavFooterProps {
  className?: string;
}

export function NavFooter({ className }: NavFooterProps) {
  const { t } = useTranslation(['new-footer']);
  const router = useRouter();

  const showSimpleFooter = useMemo(() => {
    if (
      router.pathname === '/sign-in' ||
      router.pathname === '/sign-up' ||
      router.pathname === '/set-new-password' ||
      router.pathname === '/continue-as-guest' ||
      router.pathname === '/forgot-password'
    ) {
      return true;
    }
    return false;
  }, [router.pathname]);

  return (
    <footer
      className={`${cx(className)} hidden ${
        !showSimpleFooter
          ? 'flex-col bg-[#D4F3E9] text-[#226357] md:flex '
          : 'w-full items-center justify-center text-gray-400 md:flex'
      }  `}
    >
      {!showSimpleFooter && (
        <>
          <div
            className={`mx-auto flex w-full max-w-screen-2xl flex-col justify-center p-10`}
          >
            <div className="flex max-w-6xl justify-between">
              <div className="flex flex-col gap-8">
                <NavFooterLinkColumn
                  className={`mr-[130px] xl:w-[280px]`}
                  title={null}
                >
                  <li>
                    <p className="text-[20px] font-bold leading-[36px] tracking-wider">
                      {t('the-population-project-footer')}
                    </p>
                  </li>
                  <li className="max-w-[318px]">
                    <p>{t('a-nonprofit-organisation-striving-footer')}</p>
                  </li>
                </NavFooterLinkColumn>
                <div className="">
                  <div className="flex w-[160px] flex-col items-center justify-center">
                    <p className="text-md mb-2 w-full text-left font-bold leading-[24px] tracking-[0.08em]">
                      Follow Us
                    </p>

                    <SocialIconList />
                  </div>
                </div>
              </div>
              <div className="flex gap-12 ">
                <NavFooterLinkColumn className="mr-[90px]" title={null}>
                  <li>
                    <p className="font-bold leading-[36px] tracking-wider">
                      {t('about-us-footer')}
                    </p>
                  </li>
                  <li>
                    <Link
                      prefetch={false}
                      href="/about"
                      className="hover:underline"
                    >
                      {t('about-footer')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      prefetch={false}
                      role="link"
                      href="/blog"
                      className="hover:underline"
                    >
                      {t('blog-footer')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      prefetch={false}
                      role="link"
                      href="/contact-us"
                      className="hover:underline"
                    >
                      {t('contact-us-footer')}
                    </Link>
                  </li>
                </NavFooterLinkColumn>
                <NavFooterLinkColumn
                  title={null}
                  className="flex flex-col gap-3"
                >
                  <li>
                    <p className="font-bold leading-[36px] tracking-wider">
                      {t('contributing-footer')}
                    </p>
                  </li>
                  <li>
                    <Link
                      prefetch={false}
                      href="/guides/volunteering"
                      className="hover:underline "
                    >
                      {t('about-volunteering')}
                    </Link>
                  </li>

                  <li>
                    <Link
                      prefetch={false}
                      href="/guides/volunteer-handbook"
                      className="hover:underline"
                    >
                      {t('the-volunteer-handbook-footer')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      prefetch={false}
                      href="/guides/scraping"
                      className="hover:underline"
                    >
                      The Scraping Handbook
                    </Link>
                  </li>
                  <li>
                    <Link
                      prefetch={false}
                      href="/volunteer/submit"
                      className="hover:underline"
                    >
                      {t('submit-a-list-footer')}
                    </Link>
                  </li>
                </NavFooterLinkColumn>
              </div>
            </div>
          </div>

          <div className="flex w-full justify-center bg-[#257C6B] py-4  text-sm text-white">
            <div className="flex w-full max-w-screen-2xl justify-between px-8">
              <div className="flex gap-4">
                <Link
                  href="/methodology"
                  className="font-medium hover:underline"
                >
                  {t('methodology')}
                </Link>
                <span>|</span>
                <Link href="/privacy" className="font-medium hover:underline">
                  {t('privacy-footer')}
                </Link>
              </div>

              <p className="ml-auto text-nowrap font-medium">
                The Population Project © <span>{moment().format('YYYY')}</span>{' '}
                All rights reserved.{' '}
              </p>
            </div>
          </div>
        </>
      )}
      {showSimpleFooter && <SimpleFooter />}
    </footer>
  );
}
