import {
  LogoJsonLd,
  NextSeo,
  OrganizationJsonLd,
  SocialProfileJsonLd,
} from 'next-seo';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { SITE_LOCATION } from '../../../env';

export type PageNakedLayoutProps = {
  title: string;
  description?: string;
  children?: ReactNode;
};

export default function StandardSEOWrapper({
  title,
  description,
  children,
}: Readonly<PageNakedLayoutProps>) {
  const router = useRouter();

  const canonical = useMemo(() => {
    const { asPath } = router;

    return `${SITE_LOCATION}${asPath === '/' ? '' : asPath}`;
  }, [router]);

  return (
    <>
      <NextSeo
        titleTemplate={'%s - The Population Project'}
        title={title}
        description={description}
        canonical={canonical}
        openGraph={{
          type: 'website',
          url: canonical,
          title: title,
          description: description,
          locale: 'en-US',
          images: [
            {
              url: `${SITE_LOCATION}/brand/logo-social.png`,
              alt: 'The Population Project',
            },
          ],
          site_name: 'The Population Project',
        }}
        twitter={{
          handle: '@thepopuproject',
          site: SITE_LOCATION,
          cardType: 'summary_large_image',
        }}
      />
      {/*
      To Do: this should be implemented, however it is not correct to use multiple parameters at once
      In the future we may want to
      <SiteLinksSearchBoxJsonLd
        url={SITE_LOCATION}
        potentialActions={[
          {
            target: `${SITE_LOCATION}/search?firstName={firstName}&lastName={lastName}&country={country}`,
            queryInput:
              'required name=firstName required name=lastName required name=country',
          },
        ]}
      /> */}
      <LogoJsonLd
        logo={`${SITE_LOCATION}/brand/logo-social.png`}
        url={SITE_LOCATION}
      />
      <SocialProfileJsonLd
        type="Organization"
        name="The Population Project"
        url={SITE_LOCATION}
        sameAs={[
          'https://www.linkedin.com/company/the-population-project/',
          'https://www.instagram.com/the_population_project/',
          'https://www.facebook.com/profile.php?id=100067397222842',
          'https://twitter.com/thepopuproject',
        ]}
      />
      <OrganizationJsonLd
        type="Corporation"
        id={SITE_LOCATION}
        logo={`${SITE_LOCATION}/brand/logo-social.png`}
        legalName="The Population Project"
        name="The Population Project"
        url={SITE_LOCATION}
      />
      {children}
    </>
  );
}
